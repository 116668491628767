<template>
  <!-- 我的活动 -->
  <div class="ownActivity">
    <el-divider content-position="left">我的活动</el-divider>
    <div class="activity-create" @click="toCreateActive">
      <i class="el-icon-circle-plus-outline"></i>
      <p>创建活动</p>
    </div>
    <el-divider content-position="left">我创建的活动</el-divider>
    <div v-if="activeList.length > 0">
      <template v-for="(item, index) in activeList">
        <active :item="item" :key="index" @queryActive="queryActive" :ref="index"/>
      </template>
    </div>
    <div v-else>
      <div style="margin-top: 130px">
        <listNull text="你还没有创建活动呢~" />
      </div>
    </div>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      style="text-align: right; margin-top: 30px">
    </el-pagination>
  </div>
</template>

<script>
import { queryAll } from '@/api/active.js'
import listNull from '@/components/listNull.vue'
import active from '@/components/activeList/active.vue'
export default {
  components: { active },
  data () {
    return {
      activeList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    }
  },
  mounted () {
    this.queryActive()
  },
  components: {
    listNull,
    active
  },
  methods: {
    queryActive () {
      queryAll({
        pageNO: this.pageIndex,
        pageSize: this.pageSize
      }).then(res => {
        if (res.code === 200) {
          this.activeList = res.data.list
          this.totalPage = res.data.total
          // this.setStatus()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.queryActive()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.queryActive()
    },
    // 创建活动
    toCreateActive () {
      this.$router.push({ name: 'MarketingActivities' })
    },
    // 判断状态
  //  setStatus () {
  //    // 1.进行中 2.未发布 3.未开始 4.已结束 5.倒计时
  //   let currentTime
  //   this.activeList.forEach((item, index) => {
  //     currentTime = new Date().getTime()
  //     if(item.isEnable && currentTime > new Date(item.beginTime).getTime() && currentTime < new Date(item.endTime).getTime()) {
  //       item.status = 1
  //     }else if(!item.isEnable) {
  //       item.status = 2
  //     }else if(item.isEnable && currentTime < new Date(item.beginTime).getTime() && new Date(item.beginTime).getTime() - currentTime > 605000) {
  //       console.log(3, new Date(item.beginTime).getTime() - currentTime)
  //       item.status = 3
  //     }else if(item.isEnable && currentTime > new Date(item.endTime).getTime()) {
  //       item.status = 4
  //     }else if(item.isEnable && currentTime < new Date(item.beginTime).getTime() && new Date(item.beginTime).getTime() - currentTime <= 605000) {
  //       item.status = 5
  //     }
  //   })
  //  }
  }
}
</script>

<style lang="scss" scoped>
  .ownActivity {
    padding: 30px;
    .activity-create {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ccc;
      width: 280px;
      height: 280px;
      border-radius: 20px;
      border: 1px solid #ccc;
      transition: all .2s linear;
      cursor: pointer;
      &:hover{
        box-shadow: 0 4px 12px 0 rgba(0,0,0,.08);
        transform: translate3d(0,-8px,0);
      }
      i {
        font-size: 60px;
      }
      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
</style>
